// ** React Imports
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";
// **Flatpicker
import "@styles/react/libs/flatpickr/flatpickr.scss";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

//** Uppy Css
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import "dayjs/locale/de";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const queryClient = new QueryClient();
ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </Suspense>
      </RecoilRoot>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);
